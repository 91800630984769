var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { CassandraProxyEndpoints, JunoEndpoints, MongoProxyEndpoints, PortalBackendEndpoints, } from "Common/Constants";
import { allowedAadEndpoints, allowedArcadiaEndpoints, allowedEmulatorEndpoints, allowedGraphEndpoints, allowedHostedExplorerEndpoints, allowedJunoOrigins, allowedMongoBackendEndpoints, allowedMsalRedirectEndpoints, defaultAllowedArmEndpoints, defaultAllowedBackendEndpoints, defaultAllowedCassandraProxyEndpoints, defaultAllowedMongoProxyEndpoints, validateEndpoint, } from "Utils/EndpointUtils";
export var Platform;
(function (Platform) {
    Platform["Portal"] = "Portal";
    Platform["Hosted"] = "Hosted";
    Platform["Emulator"] = "Emulator";
    Platform["Fabric"] = "Fabric";
})(Platform || (Platform = {}));
// Default configuration
let configContext = {
    platform: Platform.Portal,
    allowedArmEndpoints: defaultAllowedArmEndpoints,
    allowedBackendEndpoints: defaultAllowedBackendEndpoints,
    allowedCassandraProxyEndpoints: defaultAllowedCassandraProxyEndpoints,
    allowedMongoProxyEndpoints: defaultAllowedMongoProxyEndpoints,
    allowedParentFrameOrigins: [
        `^https:\\/\\/cosmos\\.azure\\.(com|cn|us)$`,
        `^https:\\/\\/[\\.\\w]*portal\\.azure\\.(com|cn|us)$`,
        `^https:\\/\\/cdb-(ms|ff|mc)-prod-pbe\\.cosmos\\.azure\\.(com|us|cn)$`,
        `^https:\\/\\/[\\.\\w]*portal\\.microsoftazure\\.de$`,
        `^https:\\/\\/[\\.\\w]*ext\\.azure\\.(com|cn|us)$`,
        `^https:\\/\\/[\\.\\w]*\\.ext\\.microsoftazure\\.de$`,
        `^https:\\/\\/cosmos-db-dataexplorer-germanycentral\\.azurewebsites\\.de$`,
        `^https:\\/\\/.*\\.fabric\\.microsoft\\.com$`,
        `^https:\\/\\/.*\\.powerbi\\.com$`,
        `^https:\\/\\/.*\\.analysis-df\\.net$`,
        `^https:\\/\\/.*\\.analysis-df\\.windows\\.net$`,
        `^https:\\/\\/.*\\.azure-test\\.net$`,
        `^https:\\/\\/cosmos-explorer-preview\\.azurewebsites\\.net$`,
    ],
    gitSha: process.env.GIT_SHA,
    hostedExplorerURL: "https://cosmos.azure.com/",
    AAD_ENDPOINT: "https://login.microsoftonline.com/",
    ARM_AUTH_AREA: "https://management.azure.com/",
    ARM_ENDPOINT: "https://management.azure.com/",
    ARM_API_VERSION: "2016-06-01",
    GRAPH_ENDPOINT: "https://graph.microsoft.com",
    GRAPH_API_VERSION: "1.6",
    CATALOG_ENDPOINT: "https://catalogapi.azure.com/",
    CATALOG_API_VERSION: "2023-05-01-preview",
    CATALOG_API_KEY: "",
    ARCADIA_ENDPOINT: "https://workspaceartifacts.projectarcadia.net",
    ARCADIA_LIVY_ENDPOINT_DNS_ZONE: "dev.azuresynapse.net",
    GITHUB_CLIENT_ID: "6cb2f63cf6f7b5cbdeca",
    GITHUB_TEST_ENV_CLIENT_ID: "b63fc8cbf87fd3c6e2eb",
    JUNO_ENDPOINT: JunoEndpoints.Prod,
    BACKEND_ENDPOINT: "https://main.documentdb.ext.azure.com",
    PORTAL_BACKEND_ENDPOINT: PortalBackendEndpoints.Prod,
    MONGO_PROXY_ENDPOINT: MongoProxyEndpoints.Prod,
    CASSANDRA_PROXY_ENDPOINT: CassandraProxyEndpoints.Prod,
    NEW_CASSANDRA_APIS: ["postQuery", "createOrDelete", "getKeys", "getSchema"],
    isTerminalEnabled: false,
    isPhoenixEnabled: false,
};
export function resetConfigContext() {
    if (process.env.NODE_ENV !== "test") {
        throw new Error("resetConfigContext can only be called in a test environment");
    }
    configContext = {};
}
export function updateConfigContext(newContext) {
    if (!newContext) {
        return;
    }
    if (!validateEndpoint(newContext.ARM_ENDPOINT, configContext.allowedArmEndpoints || defaultAllowedArmEndpoints)) {
        delete newContext.ARM_ENDPOINT;
    }
    if (!validateEndpoint(newContext.AAD_ENDPOINT, allowedAadEndpoints)) {
        delete newContext.AAD_ENDPOINT;
    }
    if (!validateEndpoint(newContext.EMULATOR_ENDPOINT, allowedEmulatorEndpoints)) {
        delete newContext.EMULATOR_ENDPOINT;
    }
    if (!validateEndpoint(newContext.GRAPH_ENDPOINT, allowedGraphEndpoints)) {
        delete newContext.GRAPH_ENDPOINT;
    }
    if (!validateEndpoint(newContext.ARCADIA_ENDPOINT, allowedArcadiaEndpoints)) {
        delete newContext.ARCADIA_ENDPOINT;
    }
    if (!validateEndpoint(newContext.BACKEND_ENDPOINT, configContext.allowedBackendEndpoints || defaultAllowedBackendEndpoints)) {
        delete newContext.BACKEND_ENDPOINT;
    }
    if (!validateEndpoint(newContext.MONGO_PROXY_ENDPOINT, configContext.allowedMongoProxyEndpoints || defaultAllowedMongoProxyEndpoints)) {
        delete newContext.MONGO_PROXY_ENDPOINT;
    }
    if (!validateEndpoint(newContext.MONGO_BACKEND_ENDPOINT, allowedMongoBackendEndpoints)) {
        delete newContext.MONGO_BACKEND_ENDPOINT;
    }
    if (!validateEndpoint(newContext.CASSANDRA_PROXY_ENDPOINT, configContext.allowedCassandraProxyEndpoints || defaultAllowedCassandraProxyEndpoints)) {
        delete newContext.CASSANDRA_PROXY_ENDPOINT;
    }
    if (!validateEndpoint(newContext.JUNO_ENDPOINT, allowedJunoOrigins)) {
        delete newContext.JUNO_ENDPOINT;
    }
    if (!validateEndpoint(newContext.hostedExplorerURL, allowedHostedExplorerEndpoints)) {
        delete newContext.hostedExplorerURL;
    }
    if (!validateEndpoint(newContext.msalRedirectURI, allowedMsalRedirectEndpoints)) {
        delete newContext.msalRedirectURI;
    }
    Object.assign(configContext, newContext);
}
// Injected for local development. These will be removed in the production bundle by webpack
if (process.env.NODE_ENV === "development") {
    updateConfigContext({
        PROXY_PATH: "/proxy",
        EMULATOR_ENDPOINT: "https://localhost:8081",
        PORTAL_BACKEND_ENDPOINT: PortalBackendEndpoints.Mpac,
        MONGO_PROXY_ENDPOINT: MongoProxyEndpoints.Mpac,
        CASSANDRA_PROXY_ENDPOINT: CassandraProxyEndpoints.Mpac,
    });
}
export async function initializeConfiguration() {
    try {
        const response = await fetch("./config.json", {
            headers: {
                "If-None-Match": "", // disable client side cache
            },
        });
        if (response.status === 200) {
            try {
                const externalConfig = __rest(await response.json(), []);
                updateConfigContext(externalConfig);
            }
            catch (error) {
                console.error("Unable to parse json in config file");
                console.error(error);
            }
        }
        // Allow override of platform value with URL query parameter
        const params = new URLSearchParams(window.location.search);
        if (params.has("armAPIVersion")) {
            const armAPIVersion = params.get("armAPIVersion") || "";
            updateConfigContext({ armAPIVersion });
        }
        if (params.has("armEndpoint")) {
            const ARM_ENDPOINT = params.get("armEndpoint") || "";
            updateConfigContext({ ARM_ENDPOINT });
        }
        if (params.has("aadEndpoint")) {
            const AAD_ENDPOINT = params.get("aadEndpoint") || "";
            updateConfigContext({ AAD_ENDPOINT });
        }
        if (params.has("platform")) {
            const platform = params.get("platform");
            switch (platform) {
                default:
                    console.error(`Invalid platform query parameter: ${platform}`);
                    break;
                case Platform.Portal:
                case Platform.Fabric:
                case Platform.Hosted:
                case Platform.Emulator:
                    updateConfigContext({ platform });
            }
        }
    }
    catch (error) {
        console.error("No configuration file found using defaults");
    }
    return configContext;
}
export { configContext };
